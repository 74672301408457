@font-face {
  font-family: "barlowregular";
  src: url("../../../Fonts/fonts/barlow-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../../Fonts/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.mobileContainer {
  margin-top: 60%;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.mainContainer {
  padding: 0;
  margin-top: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 25%;
  }
  .subContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    .innerContainer {
      display: flex;
      justify-content: space-between;
      margin-top: 2vh;
    }
    .component {
      width: 35%;
    }
  }
}
.btnContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8%;
  margin-bottom: 5%;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  .nextBtn {
    background: #fb651f;
    box-shadow: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "barlowregular";

    @media (min-width: 360px) and (max-width: 800px) {
      width: 100%;
      margin-top: 2vh;
      background: #fb651f;
    }

    img {
      margin-left: 1vh;
    }
  }
  .nextBtn:active {
    background: #fb651f;
    box-shadow: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "barlowregular";
  }
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #212121;
}

::-webkit-scrollbar-thumb:hover {
  background: #212121;
}
