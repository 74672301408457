.header {
  height: 12vh;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    margin-left: -40px;
  }
}

.stepper {
position: relative;
bottom: 1.2vh;
  @media only screen and (max-width: 768px) {
    position: relative;
    width: 22vw;
    bottom: 1vh;
    left: 0vh;
  }
 
}
