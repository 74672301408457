.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  @media only screen and (max-width: 768px) {
    width: 50vw;
    margin-top: 6vh;
  }
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
    margin-top: 5vw;
  }
  .subContainer {
    width: 52vw;
    margin-top: 18%;

    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      width: 50vw;
      margin-top: 20vw;
    }

    .titleContainer {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      @media (min-width: 300px) and (max-width: 800px) {
        width: 93vw;
        margin-left: 1vh;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
      }

      .title {
        // font-family: "barlowregular";
        font-family: "Martel";
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 2.6vw;
        text-align: left;
        letter-spacing: 0.25px;
        color: #0f2453;
        margin-top: 2vh;
        @media only screen and (max-width: 768px) {
          margin-top: 18vh;
          // margin-left: 1vh;
          margin-bottom: 2vh;
          line-height: 44px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-top: 7vh;
          margin-left: -1vh;
        }
      }

      .subTitle {
        margin-top: 2%;
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 2.1vw;
        text-align: left;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 5%;

        @media only screen and (max-width: 768px) {
          margin-top: 2vh;
          margin-bottom: 4vh;
          // margin-left: 1vh;

          line-height: 20px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          line-height: 20px;
          margin-left: -1vh;
        }
      }

      .address {
        font-family: "Martel";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.5vw;
        letter-spacing: 0.15px;
        color: #0f2453;
        margin-bottom: 1vh;
        @media only screen and (max-width: 768px) {
          font-size: 20px;
          line-height: 44px;
          // margin-left: 1vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-left: -1vh;
        }
      }
    }

    .addressContainer {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      @media only screen and (max-width: 768px) {
        width: 93vw;
        margin-left: 1vh;
      }

      .addressSubContainer {
        display: flex;
        align-items: space-between;
        justify-content: space-between;
        margin-top: 2vh;
        margin-bottom: 2.6vh;

        .addressTitle {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 1.3vw;
          line-height: 2.1vw;
          color: #212121;
        }

        .addressSubTitle {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 2.1vw;
          color: #212121;
          width: 35ch;
          word-break: break-word;
          @media only screen and (max-width: 768px) {
            font-size: 20px;
            line-height: 20px;
            // margin-left: 1vh;
            width: 50%;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            width: 100%;
          }
        }

        .chipBtn {
          background-color: #fb651f;
          font-size: 14px;
          font-family: "robotoregular";
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: #ffffff;
          border: none;
          letter-spacing: 0.25px;

          @media only screen and (max-width: 768px) {
            position: absolute;
            right: 2vh;
            line-height: 44px;
          }
        }
      }

      .propertyContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2vh;
        margin-top: 6vh;
        @media only screen and (max-width: 768px) {
          display: inline-block;
        }

        .propertyTitle {
          font-family: "barlowregular";
          font-style: normal;
          margin-bottom: -2vh;
          font-weight: 400;
          font-size: 16px;
          line-height: 2.3vw;
          letter-spacing: 0.15px;
          color: #212121;
          @media only screen and (max-width: 768px) {
            margin-top: 2vh;
            line-height: 20px;
            margin-bottom: 0vh;
          }
        }

        .propertyToggleBtnContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media only screen and (max-width: 768px) {
            margin: 0;
            width: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          // @media only screen and (min-width: 768px) and (max-width: 1024px) {
          //   width: 50%;
          // }
          .ownActiveBtn {
            background: #0f2453;
            border: 1px solid rgba(33, 33, 33, 0.6);
            border-radius: 4px;
            font-family: "robotoregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.3vw;
            color: #ffffff;
            text-transform: none;
            padding: 1vh 5vh 1vh 5vh;
            @media only screen and (max-width: 768px) {
              margin-top: 2vh;
              line-height: 20px;
              margin-right: 1.8vh;
              margin-left: 0vh;
            }
          }

          .ownInActiveBtn {
            background: #ffffff;
            border: 1px solid rgba(33, 33, 33, 0.6);
            border-radius: 4px;
            font-family: "barlowregular";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.3vw;
            color: rgba(33, 33, 33, 0.6);
            text-transform: none;
            padding: 1vh 5vh 1vh 5vh;
            @media only screen and (max-width: 768px) {
              margin-top: 2vh;
              line-height: 20px;
              margin-right: 1.8vh;
              margin-left: 0vh;
            }
          }
        }
      }
    }

    .residenceContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-top: 4vh;
      padding-bottom: 2vh;

      @media only screen and (max-width: 768px) {
        margin-top: 1vh;
        display: inline-block;
        margin-left: 1vh;
        // width: 92vw;
      }

      .residenceTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 2vh;
        letter-spacing: 0.15px;
        color: #212121;
        @media only screen and (max-width: 768px) {
          // margin-bottom: 2vh;
          line-height: 20px;

          // margin-left: 1vh;
        }
      }

      .residenceBtnContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px) {
          margin-top: 2vh;
          width: 66%;
          margin-left: 0vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-top: 2vh;
          // width: 35%;
          margin-left: 0vh;
        }

        .primaryActiveBtn {
          background: #0f2453;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "robotoregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0.15px;
          color: #ffffff;
          text-transform: none;
          padding: 1vh 3.2vh 1vh 3.2vh;
          text-align: center;
          @media only screen and (max-width: 768px) {
            padding: 1vh 4.2vh 1vh 4.2vh;
            line-height: 20px;
          }
        }

        .primaryActiveBtn1 {
          background: #0f2453;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "robotoregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          color: #ffffff;
          text-transform: none;
          padding: 1vh 3.5vh 1vh 3.5vh;
          @media only screen and (max-width: 768px) {
            // margin-top: 2vh;
            line-height: 20px;
          }

          @media (min-width: 600px) and (max-width: 768px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 769x) and (max-width: 1024px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 1025px) and (max-width: 1200px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 1201px) and (max-width: 1600px) {
            padding: 1vh 3.1vh 1vh 3.1vh;
          }
        }

        .primaryInActiveBtn1 {
          background: #ffffff;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          color: rgba(33, 33, 33, 0.6);
          text-transform: none;
          padding: 1vh 3.5vh 1vh 3.5vh;
          @media (min-width: 300px) and (max-width: 800px) {
            line-height: 20px;

            // margin-left: 1vh;
          }

          @media (min-width: 600px) and (max-width: 768px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 769x) and (max-width: 1024px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 1025px) and (max-width: 1200px) {
            padding: 1vh 2.7vh 1vh 2.7vh;
          }

          @media (min-width: 1201px) and (max-width: 1600px) {
            padding: 1vh 3.1vh 1vh 3.1vh;
          }
        }

        .primaryInActiveBtn {
          background: #ffffff;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0.15px;
          color: rgba(33, 33, 33, 0.6);
          text-transform: none;
          padding: 1vh 3.2vh 1vh 3.2vh;
          text-align: center;
          @media (min-width: 300px) and (max-width: 800px) {
            line-height: 20px;
            padding: 1vh 4.2vh 1vh 4.2vh;
          }
        }
      }
    }

    .goalContainer {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 2vh;
      @media only screen and (max-width: 768px) {
        width: 92vw;
        margin-left: 1vh;
      }
      // @media only screen and (min-width: 768px) and (max-width: 1024px) {
      //   width: 92vw;
      //   margin-left: 1vh;
      // }

      .goalTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3vw;
        letter-spacing: 0.15px;
        color: #212121;
        margin-bottom: 2vh;
        margin-top: 6vh;
        @media only screen and (max-width: 768px) {
          margin-left: 1vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-left: 1vh;
        }
      }

      .drop {
        color: #212121;
      }
    }
    .goalHideContainer {
      display: none;
    }
    .dropDown {
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-left: 0vh;
      }
    }
    .projectSpecContainer {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 2vh;
      @media only screen and (max-width: 768px) {
        width: 91vw;
        margin-left: 1vh;
      }

      .projectTitle {
        font-family: "Martel";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 2.1vw;
        color: #0f2453;
        margin-top: 4vh;
        margin-bottom: 4vh;
        @media only screen and (max-width: 768px) {
          margin-bottom: 6vh;
          // margin-left: 1vh;
        }
      }

      .projContainer {
        .projTitle {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0.15px;
          color: #0f2453;
          margin-bottom: 3vh;
          @media only screen and (max-width: 768px) {
            // margin-left: 1vh;
          }
        }
      }
    }

    .serviceContainer {
      @media only screen and (max-width: 768px) {
        width: 95vw;
      }
      .serviceTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6vw;
        letter-spacing: 0.15px;
        color: #212121;
        margin-bottom: 2%;
        margin-top: 4%;
        @media only screen and (max-width: 768px) {
          margin-left: 1vh;
        }
      }

      .serviceBtnContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        @media only screen and (max-width: 768px) {
          margin-top: 2vh;
          display: inline-flex;
          line-height: 20px;
          width: 93vw;
          margin-left: 0.8vh;
          box-shadow: none;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-top: 2vh;
          display: inline-flex;
          line-height: 20px;
          // width: 93vw;
          margin-left: 0.8vh;
        }

        .selectedBtn1,
        .selectedBtn2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          width: 12.2vw;
          height: 2vh;
          background: #0f2453;
          border: 0;
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0.15px;
          color: #ffffff;
          padding-top: 3vh;
          padding-bottom: 3vh;
          padding-left: 0;
          padding-right: 0;
          margin-top: 1vh;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            margin-top: 2vh;
            display: inline-flex;
            line-height: 20px;
            border: none;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 2vh;
            display: inline-flex;
            line-height: 20px;
          }
        }
        .selectedBtn1 {
          @media only screen and (max-width: 768px) {
            margin-top: 2vh;
            margin-right: 1vh;
            width: 46%;
            padding-top: 2vh;
            padding-bottom: 2vh;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 2vh;
            margin-right: 1vh;
            width: 46%;
            padding-top: 2vh;
            padding-bottom: 2vh;
          }
        }

        .selectedBtn2 {
          margin-top: 1vh;
          @media only screen and (max-width: 768px) {
            margin-right: 1vh;
            margin-top: 2vh;
            padding-bottom: 2vh;
            padding-top: 2vh;
            width: 46%;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-right: 1vh;
            margin-top: 2vh;
            padding-bottom: 2vh;
            padding-top: 2vh;
            width: 46%;
          }
        }

        .notSelectedBtn1,
        .notSelectedBtn2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          width: 12.2vw;
          height: 2vh;
          background: #fff;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          letter-spacing: 0.15px;
          color: #21212199;
          padding-top: 3vh;
          padding-bottom: 3vh;
          padding-left: 0;
          padding-right: 0;
          margin-top: 1vh;
          cursor: pointer;
          @media only screen and (max-width: 768px) {
            margin-top: 2vh;
            margin-right: 1vh;
            width: 46%;
            padding-top: 2vh;
            padding-bottom: 2vh;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin-top: 2vh;
            margin-right: 1vh;
            width: 46%;
            padding-top: 2vh;
            padding-bottom: 2vh;
          }
        }

        // .notSelectedBtn2 {
        //   margin-top: 2vh;
        //   //   @media (min-width: 300px) and (max-width: 800px) {
        //   //     width: 40%;
        //   //     display: inline-block;
        //   //   }
        // }
      }
    }

    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20%;
      margin-bottom: 10%;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 10%;
        margin-bottom: 25%;
        width: 93.4vw;
      }

      .backBtn {
        background: #ffffff;
        border: 1px solid #fb651f;
        border-radius: 4px;
        display: flex;
        font-weight: 500;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #fb651f;
        font-family: "robotoregular";
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          width: 97%;
        }

        img {
          margin-right: 1vh;
        }
      }

      .nextBtn {
        background: #fb651f;
        border-radius: 4px;
        display: flex;
        font-weight: 500;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "barlowregular";
        box-shadow: none;
        border: none;
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          width: 98%;
        }
        img {
          margin-left: 1vh;
        }
      }
    }
  }
}
