@font-face {
  font-family: "barlowregular";
  src: url("../../../../Fonts/fonts/barlow-regular-webfont.woff2")
      format("woff2"),
    url("../../../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../../../Fonts/fonts/roboto-regular-webfont.woff2")
      format("woff2"),
    url("../../../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  margin: 0;
  padding: 0;
  .summaryTitleContainer {
    width: 100%;
    margin-top: 2vh;
    .summaryTitle {
      font-family: "Martel";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      letter-spacing: 0.25px;
      color: #0f2453;
    }
    .projectTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(33, 33, 33, 0.6);
      margin-top: 2vh;
      margin-bottom: 2vh;
    }
    .projectInfo {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: rgba(33, 33, 33, 0.6);
    }
  }
  .projectInfoContainer {
    width: 100%;
    margin-top: 4vh;
    .projectSubTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 3vh;
    }
    .addressSubTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #212121;
      margin-top: 2vh;
      margin-bottom: 4vh;
    }
    .zone {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.25px;
      color: #212121;
      margin-left: 0vh;
      .infoIcon2 {
        margin-left: 1vh;
        width: 15px;
        height: 15px;
      }
    }
    .projectTitleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-top: 3vh;
      .addressTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 1vh;
      }
      .editIcon {
        margin-bottom: 1vh;
      }
    }
  }
  .projectSpecContainer {
    width: 100%;
    margin-top: 4vh;
    .projectSpecContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-top: 3vh;
    }
    .projectSpecTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 1vh;
    }
    .specEditIcon {
      margin-bottom: 1vh;
    }
    .projectSpecSubTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(33, 33, 33, 0.6);
      margin-top: 2vh;
    }
    .projectAns {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #212121;
      margin-bottom: 4vh;
    }
    .projectAns::first-letter {
      text-transform: capitalize;
    }
  }
  .aduPrefContainer {
    width: 100%;
    margin-top: 4vh;
    .aduPrefSubContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      margin-top: 3vh;
      .aduTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 1vh;
      }
      .aduEditIcon {
        margin-bottom: 1vh;
      }
    }
    .aduTypeTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      color: rgba(33, 33, 33, 0.6);
      margin-top: 2vh;
    }
    .aduTypeTitleInfo {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #212121;
    }
    .aduDiv,
    .jaduDiv {
      display: flex;
      align-items: end;
      justify-content: flex-start;
      padding-top: 2vh;
      padding-bottom: 2vh;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .optionOneContainer,
      .roomContainer {
        width: 60%;
        .optionOne,
        .optionTwo {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: rgba(33, 33, 33, 0.6);
          margin-bottom: 0.5vh;
        }
        .squareFeetTitle,
        .roomCount {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: rgba(33, 33, 33, 0.6);
        }
        .squareFeetInfo,
        .roomCountInfo {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #212121;
        }
      }
      .roomContainer {
        width: 40%;
      }
    }
    .jaduDiv {
      border: none;
    }
    .jaduTypeTitleInfo {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #212121;
      margin-top: 2vh;
    }
  }
  .contactInfoContainer {
    width: 100%;
    margin-top: 4vh;
  }
  .contactSubContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 3vh;
    .contactTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 1vh;
    }
    .contactEditIcon {
      margin-bottom: 1vh;
    }
  }
  .nameDiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1vh;
    .firstNameContainer,
    .lastNameContainer {
      width: 60%;
      .firstNameTitle,
      .lastNameTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.25px;
        color: rgba(33, 33, 33, 0.6);
        min-height: 15px;
      }
      .firstNameInfo,
      .lastNameInfo {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        // line-height: 24px;
        letter-spacing: 0.1px;
        color: #212121;
        min-height: 15px;
        word-wrap: break-word;
        width: 70%;
      }
    }
    .lastNameContainer {
      width: 40%;
    }
  }
}
