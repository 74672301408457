@font-face {
  font-family: "barlowregular";
  src: url("../../../Fonts/fonts/barlow-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../../Fonts/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  .subContainer {
    width: 40vw;
    margin-top: 18%;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      margin-top: 35%;
      width: 100%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 15%;
      width: 50vw;
    }
    .title {
      // font-family: "barlowregular";
      font-style: normal;
      font-family: "Martel";
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #0f2453;
      @media only screen and (max-width: 768px) {
        line-height: 44px;
        margin-top: 8vh;
        margin-bottom: 2vh;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        line-height: 44px;
        margin-top: 8vh;
      }
    }
    .subTitle {
      margin-top: 3%;
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 5%;
      @media only screen and (max-width: 768px) {
        margin-bottom: 4vh;
      }
    }
    .addressContainer {
      margin-bottom: 5%;
      width: 100%;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding-bottom: 1.5vw;
      .inputFields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1vh;
        gap: 8px;

        @media only screen and (max-width: 768px) {
          // display: block;
          width: 100%;
          flex-direction: column;
          padding-right: 1vh;
          // padding-left: 1vh;
          // margin-bottom: 2vh;
        }

        .firstName {
          margin-top: 2vh;
          // margin-bottom: 2vh;
          // margin-right: 1vh;

          @media only screen and (max-width: 768px) {
            // margin-left: 1vh;
          }
        }

        .lastName {
          width: 100%;
          margin-top: 2vh;
          margin-bottom: 2vh;
          margin-left: 1vh;
          margin-bottom: 0vh;
          @media only screen and (max-width: 768px) {
            position: relative;
            right: 1vh;
            margin-left: 2vh;
          }
        }
      }
    }
    .inputFields2 {
      margin-bottom: 1vh;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0vh;
      }
    }
    .addressContainer2 {
      display: flex;
    }
    .addressError {
      color: red;
      font-family: "barlowregular";
      font-size: 14px;
      margin-top: 2vh;
      margin-bottom: 2vh;
    }
    .addressNoError {
      display: none;
    }
    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10%;
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 35%;
        margin-bottom: 25%;
      }
      .backBtn {
        background: #ffffff;
        border: 1px solid #fb651f;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #fb651f;
        font-family: "robotoregular";
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          width: 100%;
        }
        img {
          margin-right: 1vh;
          @media only screen and (max-width: 768px) {
            margin: 0 6px 0px 0;
          }
          @media only screen and (max-width: 768px) {
            margin: 0 6px 0px 0;
          }
        }
      }
      .nextBtn {
        background: #fb651f;
        box-shadow: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "barlowregular";
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          width: 100%;
        }
        img {
          margin-left: 1vh;
          @media only screen and (max-width: 768px) {
            margin: 0 0 0px 4px;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            margin: 0 0 -3px 4px;
          }
        }
      }
    }
  }
}
