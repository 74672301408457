@font-face {
  font-family: "barlowregular";
  src: url("../../Fonts/fonts/barlow-regular-webfont.woff2") format("woff2"),
    url("../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../Fonts/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  @media (min-width: 300px) and (max-width: 800px) {
    width: 100%;
  }

  .subContainer {
    width: 55vw;
    height: 35vw;
    margin-left: 3vw;
    margin-top: 5%;
    margin-bottom: 10%;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    border: 1px solid #e0e0e0;
    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
    border-radius: 12px;
    @media only screen and (max-width: 800px) {
      display: none;
    }
    .reportContainer {
      margin-top: 10%;
      margin-left: 5%;
      @media only screen and (max-width: 800px) {
        margin-top: 4vh;
      }
      .reportTitle {
        // font-family: "barlowregular";
        font-family: "Martel";
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 2.5vw;
        letter-spacing: 0.25px;
        color: #0f2453;
        margin-bottom: 2%;

        @media only screen and (max-width: 768px) {
          display: none;
        }
      }

      .reportSubTitle {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4vw;
        letter-spacing: 0.15px;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 3%;

        @media (min-width: 300px) and (max-width: 800px) {
          line-height: 20px;
          position: relative;
          bottom: 18vh;
          margin-bottom: 1vh;
          margin-top: 5vh;
        }
      }

      .reportCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 6vh;
        background: rgba(235, 234, 236, 0.2);
        border: 1px solid #e0e0e0;
        border-radius: 16px;
        @media (min-width: 300px) and (max-width: 800px) {
          width: 92%;
          margin-top: -15vh;
          height: 20vh;
        }
        img {
          margin-top: 10%;
          margin-bottom: 2%;
        }

        .reportName {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.4vw;
          text-align: center;
          letter-spacing: 0.15px;
          color: rgba(33, 33, 33, 0.6);
          margin-bottom: 4%;
          @media (min-width: 300px) and (max-width: 800px) {
            line-height: 20px;
          }
        }

        .shareTitle {
          font-family: "robotoregular";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: #0f2453;
          margin-bottom: 10%;

          img {
            margin-bottom: -0.3vh;
          }
        }
      }

      .btnContainer {
        margin-top: 5%;
        margin-right: 6vh;
        width: 30vw;

        .consultationBtn {
          background: #fb651f;
          border-radius: 4px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          font-family: "barlowregular";
          color: #ffffff;
          border: none;
          box-shadow: none;
          margin-bottom: 5%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 6px 4px;
          width: 30vw;
          height: 4.5vh;
          cursor: pointer;

          @media (min-width: 300px) and (max-width: 800px) {
            width: 80vw;
          }
        }

        .downloadBtn {
          background: #ffffff;
          border: 1px solid #fb651f;
          border-radius: 4px;
          letter-spacing: 1.25px;
          text-transform: uppercase;
          color: #fb651f;
          display: none;
        }
      }
    }

    .rightImgContainer {
      height: 100% !important;
      img {
        height: 100%;
        border-radius: 12px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        @media (min-width: 300px) and (max-width: 800px) {
          width: 68%;
          position: absolute;
          left: 2vh;
          bottom: 0vh;
          height: 35vh;
        }
      }
    }
  }
}
.mobileContainer {
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  .mobileReportTitle {
    font-family: "barlowregular";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.25px;
    color: #0f2453;
    margin-top: 8vh;
    margin-bottom: 2vh;
  }
  .mobileReportSubTitle {
    font-family: "barlowregular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: rgba(33, 33, 33, 0.6);
  }
  .mobileReportCard {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding-top: 2vh;
    margin-top: 2vh;
    margin-bottom: 6vh;
    .mobileReportSubCard {
      background: rgba(235, 234, 236, 0.2);
      border: 1px solid #e0e0e0;
      border-radius: 16px;
      height: 20vh;
      margin-left: 1.5vh;
      margin-right: 1.5vh;
      .dataContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .dataTick {
          margin-top: 15%;
          margin-bottom: 2vh;
        }
        .dataReport {
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          letter-spacing: 0.15px;
          color: rgba(33, 33, 33, 0.6);
        }
      }
    }
    .btnMobileContainer {
      margin-top: 8%;
      margin-bottom: 2vh;
      padding-left: 1.5vh;
      padding-right: 1.5vh;
      .consultationMobileBtn {
        background: #fb651f;
        border-radius: 4px;
        font-family: "barlowregular";
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        cursor: pointer;
        width: 100%;
        border: none;
        box-shadow: none;
        height: 36px;
      }
    }
    .leftImgContainer {
      margin-top: 30%;
    }
  }
}

::-webkit-scrollbar {
  width: 1px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #212121;
}

::-webkit-scrollbar-thumb:hover {
  background: #212121;
}
