.mainContainer {
  margin: 0;
  padding: 0;

  .paymentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 300px) and (max-width: 800px) {
      width: 100%;
    }
    .title {
      font-size: 4vw;
      color: red;
      text-align: start;
      @media (min-width: 300px) and (max-width: 800px) {
        width: 100%;
        margin-top: 5vh;
      }
    }
    .message {
      margin-top: 4vh;
      font-size: 2vw;
      text-align: start;
    }
  }
}
