@font-face {
  font-family: "barlowregular";
  src: url("../../../../Fonts/fonts/barlow-regular-webfont.woff2")
      format("woff2"),
    url("../../../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../../../Fonts/fonts/roboto-regular-webfont.woff2")
      format("woff2"),
    url("../../../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.orderPage {
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: 1vh;
  width: 20vw;
  height: auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  @media only screen and (max-width: 768px) {
    margin-top: 2vh;
    width: 85%;
    // width: 72vw;
    // right: 61vw;
    // position: relative;
    padding-top: 2vh;
  }
  @media screen and (max-width: 390px) and (max-height: 850px) {
    margin-top: 10vh;
  }
  .orderTitle {
    // font-family: "barlowregular";
    font-family: "Martel";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 3vw;
    letter-spacing: 0.25px;
    color: #0f2453;
    min-width: 17vw;
    margin-top: 1vh;
    margin-bottom: 1.1vw;
    @media only screen and (max-width: 768px) {
      // width: 90vw;
      margin-bottom: 3vh;
    }
  }

  .reportContainer {
    display: flex;
    justify-content: flex-start;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 2vh;

    img {
      margin-right: 2vh;
    }
    .titleCard {
      .propertyTitle {
        font-style: normal;
        font-family: "barlowregular";
        font-weight: 400;
        font-size: 1vw;
        line-height: 1.4vw;
        letter-spacing: 0.25px;
        color: #212121;
        margin-top: 1vh;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
          margin-bottom: 2vh;
        }
      }

      .propertyRate {
        font-family: "barlowregular";
        font-style: normal;
        font-weight: 400;
        font-size: 1.2vw;
        line-height: 1.4vw;
        color: #212121;
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }

  .taxContainer {
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1vh;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);

    .taxTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 1.8vw;
      letter-spacing: 0.44px;
      color: rgba(33, 33, 33, 0.6);

      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .totalTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 500;
      font-size: 1.5vw;
      line-height: 1.8vw;
      letter-spacing: 0.44px;
      color: rgba(33, 33, 33, 0.6);

      @media only screen and (max-width: 768px) {
        font-weight: 500;
        font-size: 20px;
      }
    }

    .priceTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 1.1vw;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #212121;

      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
  }

  .termsAndConditions {
    font-family: "barlowregular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 5%;

    @media (min-width: 300px) and (max-width: 800px) {
      font-size: 12px;
      margin-bottom: 2vh;
    }

    .policyTag {
      color: #fb651f;
      cursor: pointer;
    }
  }

  .btnContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 8%;
    margin-bottom: 5%;
    @media only screen and (max-width: 768px) {
      display: none;
    }

    .backBtn {
      background: #ffffff;
      border: none;
      border-radius: 4px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #fb651f;
      font-family: "robotoregular";

      img {
        margin-right: 1vh;
      }
    }

    .nextBtn {
      background: #fb651f;
      box-shadow: none;
      border-radius: 4px;
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.25px;
      text-transform: uppercase;
      color: #ffffff;
      font-family: "barlowregular";
      margin-bottom: 5%;

      img {
        margin-left: 1vh;
      }
    }
  }
}
