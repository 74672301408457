.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-top: 6vh;
  }

  .subContainer {
    width: 45vw;
    margin-top: 22%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-right: 8vh;
      margin-bottom: 20vh;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 25%;
      width: 65%;
      margin-right: 6vh;
    }

    .title {
      // font-family: "barlowregular";
      font-family: "Martel";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 2.6vw;
      text-align: center;
      letter-spacing: 0.25px;
      color: #0f2453;
      margin-bottom: 1vh;
      // width: 254px;
      // height: 40px;
      @media only screen and (max-width: 768px) {
        margin-top: 14vh;
        margin-left: 4vh;
        margin-bottom: 2vh;
        line-height: 44px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 2vh;
        margin-left: 0vh;
        margin-bottom: 2vh;
        line-height: 44px;
      }
    }

    .subTitle {
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 2.1vw;
      text-align: left;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 5%;
      width: 100%;
      height: 20px;
      @media only screen and (max-width: 768px) {
        line-height: 20px;
        margin-left: 4vh;
      }
    }

    .selectAdu {
      margin-top: 4.2vh;

      .selectTitleContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1vw;
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          margin-left: 4vh;
          width: 95vw;
          // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .selectTitle {
          font-family: "Martel";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 1.7vw;
          letter-spacing: 0.15px;
          color: #0f2453;
          @media only screen and (max-width: 768px) {
            line-height: 20px;
          }
        }

        img {
          margin-left: 1vh;
        }
      }

      .selectSubTitle {
        font-family: "robotoregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.8vw;
        letter-spacing: 0.44px;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 5%;
        @media only screen and (max-width: 768px) {
          margin-left: 4vh;
          line-height: 20px;
        }
      }

      .selectCardContainer {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 2vh;
        cursor: pointer;
        @media only screen and (max-width: 768px) {
          overflow-x: auto;
          width: 99vw;
          height: 100%;
          margin-left: 2vh;
        }

        .accessoryInActiveCard,
        .accessoryActiveCard {
          margin-right: 2vh;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.12);
          box-shadow: none;
          margin-left: 2px;
          border-radius: 8px;
          @media only screen and (max-width: 768px) {
            margin-left: 2vh;
            margin-right: 1vh;
          }

          input[type="checkbox"] {
            accent-color: #0f2453;
            margin: 1vh 1vh 0 1vh;
            border: 1px solid #0f2453;
            width: 2.5vw;
            height: 2.5vh;
            @media only screen and (max-width: 768px) {
              width: 4.5vw;
            }
          }

          .checkBoxContainer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            accent-color: #0f2453;
            margin: 1vh;
          }

          .accessoryContent {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 12vw;
            height: 8vw;
            @media only screen and (max-width: 768px) {
              height: 12vh;
              width: 40vw;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
              width: 20vw;
              height: 10vh;
            }

            img {
              height: 5vh;
              // margin-bottom: 2.5vh;
            }

            .accessoryCardTitle {
              font-family: "barlowregular";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              margin-top: 1.5vh;
              text-align: center;
              letter-spacing: 0.1px;
              color: #14395b;
              @media only screen and (max-width: 768px) {
                margin-top: 0vh;
              }
            }
          }
        }

        .accessoryActiveCard {
          background: #ffffff;
          border: 1px solid #0f2453;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        }

        .lastInActiveCard,
        .lastActiveCard {
          margin-right: 2vh;
          padding-bottom: 4vh;
          border-radius: 8px;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0, 0.12);
          box-shadow: none;
          @media only screen and (max-width: 768px) {
            padding-bottom: 8vh;
            margin-left: 2vh;
            // height: 14vh;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            padding-bottom: 8vh;
          }

          input[type="checkbox"] {
            accent-color: #0f2453;
            margin: 1vh 1vh 0 1vh;
            border: 1px solid #0f2453;
            width: 2.5vw;
            height: 2.5vh;
            @media only screen and (max-width: 768px) {
              width: 4.5vw;
            }
          }

          .lastCheckBoxContainer {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            width: 97%;
            margin-top: 0.5vh;
          }

          .lastCardContent {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 12vw;

            @media only screen and (max-width: 768px) {
              height: 6vh;
              width: 50vw;
            }
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
              height: 4vh;
              width: 30vw;
            }

            img {
              height: 3vh;
              margin-top: 3vh;
            }

            .lastCardTitle {
              font-family: "barlowregular";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              text-align: center;
              letter-spacing: 0.1px;
              margin-top: 2.5vh;
              color: #14395b;
            }
          }
        }

        .lastActiveCard {
          background: #ffffff;
          border: 1px solid #0f2453;
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
        }
      }
    }

    .specialContainer {
      margin-top: 5%;

      .specialTitle {
        font-family: "Martel";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.6vw;
        letter-spacing: 0.15px;
        color: #0f2453;
        margin-bottom: 1vw;
        @media only screen and (max-width: 768px) {
          margin-bottom: 2vh;
          line-height: 25px;
          margin-left: 4vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 2vh;
          line-height: 25px;
          // margin-left: 4vh;
        }
      }

      .specialSubTitle {
        font-family: "robotoregular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.8vw;
        letter-spacing: 0.44px;
        color: rgba(33, 33, 33, 0.6);
        margin-bottom: 8%;
        @media only screen and (max-width: 768px) {
          margin-bottom: 4vh;
          line-height: 20px;
          margin-left: 4vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          margin-bottom: 4vh;
          line-height: 20px;
          // margin-left: 4vh;
        }
      }

      .selectInputContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 46vw;
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-left: 4vh;
          line-height: 20px;
          margin-bottom: 0vh;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          width: 73vw;
          margin-bottom: 2vh;
        }

        // @media screen and (min-width: 1280px) {
        //   width: 46vw;
        // }

        @media screen and (min-width: 1920px) {
          width: 43vw;
        }
        @media screen and (min-width: 1440px) {
          width: 44vw;
        }

        .specialLeftInActiveBtn,
        .specialLeftActiveBtn {
          margin-right: 1vh;
          margin-bottom: 1.5vh;
          background: #ffffff;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          color: rgba(33, 33, 33, 0.6);
          text-transform: capitalize;
          height: 8vh;
          @media (min-width: 300px) and (max-width: 800px) {
            line-height: 15px;
            height: 9vh;
            // padding: 1vh;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            line-height: 20px;
            height: 8vh;
          }
        }

        .specialLeftActiveBtn {
          background-color: #0f2453;
          color: #ffffff;
        }

        .specialRightBtnInActiveBtn,
        .specialRightBtnActiveBtn {
          margin-left: 1vh;
          // width: 32vw;
          margin-bottom: 1.5vh;
          background: #ffffff;
          border: 1px solid rgba(33, 33, 33, 0.6);
          border-radius: 4px;
          border-radius: 4px;
          font-family: "barlowregular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.3vw;
          color: rgba(33, 33, 33, 0.6);
          text-transform: capitalize;
          height: 8vh;
          @media only screen and (max-width: 768px) {
            line-height: 15px;
            height: 9vh;
            // padding: 0;
          }
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            line-height: 15px;
            height: 8vh;
          }
        }

        .specialRightBtnActiveBtn {
          background-color: #0f2453;
          color: #ffffff;
        }
      }
    }

    .requiredBox {
      margin-left: 0.5vh;
      @media only screen and (max-width: 768px) {
        margin-left: 2vh;
      }
    }

    .requiredBox1 {
      margin-left: 0vh;
      @media only screen and (max-width: 768px) {
        margin-left: 4vh;
      }
    }

    .btnContainer {
      width: 45vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8%;
      margin-bottom: 5%;
      @media only screen and (max-width: 768px) {
        width: 98vw;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 73vw;
      }
      @media screen and (min-width: 1920px) {
        width: 95%;
      }
      @media screen and (min-width: 1440px) {
        width: 97%;
      }

      .backBtn {
        background: #ffffff;
        border: 1px solid #fb651f;
        border-radius: 4px;
        display: flex;
        font-family: "robotoregular";
        font-size: 14px;
        font-weight: 500;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #fb651f;
        @media only screen and (max-width: 768px) {
          width: 91%;
          margin-top: 15vh;
          margin-left: 4vh;
          position: absolute;
        }

        img {
          margin-right: 1vh;
        }
      }

      .nextBtn {
        background: #fb651f;
        box-shadow: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        margin-right: -1vh;
        font-family: "barlowregular";
        @media only screen and (max-width: 768px) {
          width: 95%;
          margin-top: 2vh;
          margin-left: 4vh;
        }
        img {
          margin-left: 1vh;
        }
      }
    }
  }
}

* {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none;
}
