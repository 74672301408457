@font-face {
  font-family: "barlowregular";
  src: url("../../../Fonts/fonts/barlow-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/barlow-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../../../Fonts/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../../../Fonts/fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 14%;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin-top: 20%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    @media only screen and (max-width: 768px) {
      width: 100vw;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      max-width: 100vw;
    }

    .title {
      font-family: "Martel";
      font-style: normal;
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      letter-spacing: 0.25px;
      color: #0f2453;

      @media only screen and (max-width: 768px) {
        margin-top: 28%;
        width: 96%;
        margin-left: 5vh;
        margin-bottom: 2vh;
        line-height: 44px;
      }
      // @media only screen and (min-width: 768px) and (max-width: 1024px) {
      //   margin-top: 10%;
      //   margin-left: 7vh;
      // }
    }

    .subTitle {
      margin-top: 3%;
      font-family: "barlowregular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: rgba(33, 33, 33, 0.6);
      margin-bottom: 5%;

      @media only screen and (max-width: 768px) {
        margin-top: 2vh;
        width: 100vw;
        margin-left: 4vh;
      }
    }

    .card {
      // width: 80%;
      margin: 0 25vh 0 25vh;
      // min-height: 16vh;
      background: rgba(235, 234, 236, 0.2);
      border: 1px solid #e0e0e0;
      color: #21212199;
      font-family: "barlowregular";
      border-radius: 8px;
      padding: 2vh 0 2vh 4vh;
      box-shadow: none;
      @media only screen and (max-width: 768px) {
        // width: 80%;
        margin: 0 25vh 0 25vh;
        // min-height: 16vh;
        background: rgba(235, 234, 236, 0.2);
        border: 1px solid #e0e0e0;
        color: #21212199;
        font-family: "barlowregular";
        border-radius: 8px;
        padding: 2vh 0 2vh 4vh;
        box-shadow: none;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        // width: 80%;
        margin: 0;
        // min-height: 16vh;
        background: rgba(235, 234, 236, 0.2);
        border: 1px solid #e0e0e0;
        color: #21212199;
        font-family: "barlowregular";
        border-radius: 8px;
        padding: 2vh 0 2vh 4vh;
        box-shadow: none;
      }
    }

    .formContent {
      font-size: 16px;
      font-family: "barlowregular";
      line-height: 20px;
      padding-top: 17px;
    }

    .pointIcon {
      padding-right: 14px;
      font-family: "barlowregular";
    }

    .button {
      margin-top: 5%;
      margin-bottom: 8%;
      margin-left: 25vh;
      margin-right: 25vh;
      background: #fb651f;
      font-weight: 500;
      box-shadow: none;
      border-radius: 4px;
      font-size: 14px;
      font-family: "barlowregular";
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-left: 0;
        margin-right: 0;
      }
      img {
        margin-left: 1vh;
        margin-bottom: 2px;
      }
    }
  }
}

.mobContainer {
  padding: 0;
  margin: 0;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  .mobTitle {
    font-family: "Martel";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #0f2453;
    margin-top: 5%;
  }
  .mobSubTitle {
    margin-top: 5%;
    font-family: "barlowregular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(33, 33, 33, 0.6);
    margin-bottom: 5%;
    padding-left: 1vh;
    padding-right: 1vh;
  }
  .mobCard {
    // margin: 0 6vh 0 9vh;
    background: rgba(235, 234, 236, 0.2);
    border: 1px solid #e0e0e0;
    color: #21212199;
    font-family: "barlowregular";
    border-radius: 8px;
    padding: 2vh 0 2vh 4vh;
    box-shadow: none;
    margin-bottom: 5%;
    .formMobContent {
      font-size: 16px;
      font-family: "barlowregular";
      line-height: 20px;
      padding-top: 17px;
    }
    .pointMobIcon {
      padding-right: 14px;
      font-family: "barlowregular";
      text-align: left;
    }
  }
}
.btnMobContainer {
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  margin-bottom: 30%;
  @media only screen and (min-width: 768px) {
    display: none;
  }

  .nextMobBtn {
    background: #fb651f !important;
    box-shadow: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "barlowregular";
    img {
      margin-left: 1vh;
    }
  }
}
